import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import moose from '../img/moose.png';

function headerTextColorForBg(bg) {
  return !bg || bg === '#fff' ? '#009bce' : '#009bce';
}

function bodyTextColorForBg(bg) {
  return !bg || bg === '#fff' ? '#000' : '#dadada';
}

const StyledIntro = styled.section`
  padding: 5rem 0;
  padding-top: 5rem;
  position: relative;
  z-index: 1;
  background: ${(props) => (!props.fields.intro_bg || props.fields.intro_bg === '#fff' ? '#fff' : null)};
  display: ${(props) => (props.fields.name === 'agency-careers' ? 'none' : 'block')};

  & .intro-copy {
    padding: 0 ${(props) => (props.fields.name === 'agency-staff' ? '76px' : '30px')} 0 30px;
    max-width: ${(props) => (props.fields.intro_copy_width ? props.fields.intro_copy_width + 'px' : null)};
    color: ${(props) => bodyTextColorForBg(props.fields.intro_bg)};
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1.25rem;
    line-height: 1.55;
    margin: 0 auto;
    transition: all 200ms linear;
    a {
      color: #009bce;
      &:hover {
        color: black;
      }
    }
    @media (max-width: 1175px) {
      padding: 0 30px;
      margin: 0 auto;
    }
    @media (max-width: 1040px) {
      padding-top: ${(props) => (props.fields.name === 'agency-staff' ? '100px' : null)};
    }
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }
  & h1,
  & h2 {
    font-family: 'Oswald';
    font-size: 1.9rem;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 1.125rem;
    color: ${(props) => headerTextColorForBg(props.fields.intro_bg)};
    font-weight: 400;
    letter-spacing: 1.4px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }

  & p {
    line-height: 1.9;
  }
  & ul {
    margin-left: 0;
    padding-left: 30px;
  }
  & li {
    margin-bottom: 1rem;
  }
  .moose {
    display: ${(props) => (props.fields.name === 'agency-staff' ? 'block' : 'none')};
    background-image: url(${moose});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    max-width: 583px;
    width: 100%;
    height: 582px;
    margin: auto auto 0;
    position: absolute;
    top: -500px;
    right: 0;
    z-index: -1;
    transform-origin: bottom right;
    @media (max-width: 1540px) {
      transform: scale(0.8);
    }
    @media (max-width: 1450px) {
      transform: scale(0.6);
    }
    @media (max-width: 1040px) {
      top: -400px;
      right: 0;
      left: 0;
      margin: auto;
      transform-origin: bottom center;
    }
    @media (max-width: 1024px) {
      .mobile {
        display: ${(props) => (props.fields.name === 'agency-staff' ? 'block' : 'none')};
      }
    }
  }
  //Cognito Form styles for /partner-performance-review/ can be removed when that Landing Page is done.
  .cog-1344 {
    --small-text: 15px;
    --h2__font-size: 1.5em;
    --font-family: 'Roboto';
    --font-weight: 400;
    --heading__font-family: 'Oswald';
    --label__font-family: 'Roboto';
    --label__font-size: 0.9em;
  }
  .cog-rating-scale__question label {
    color: #333 !important;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto' !important;
  }
  th.cog-rating-scale__question {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .cog-section__heading.cog-heading {
    margin-bottom: 0.5em !important;
  }
  .cog-helptext {
    color: #202020 !important;
  }
  .cog-1344 .cog-asterisk {
    color: rgb(0, 165, 217) !important;
  }
  @media (min-width: 731px) {
    .cog-1344 div[data-page='3'] .cog-rating-scale .cog-section__inner > table thead th {
      position: sticky !important;
      top: 104px !important;
      background: white !important;
      z-index: 5 !important;
    }
  }
  @media (min-width: 901px) {
    .cog-1344 div[data-page='3'] .cog-rating-scale .cog-section__inner > table thead th {
      top: 0px !important;
    }
  }
  @media (min-width: 1000px) {
    .cog-1344 div[data-page='3'] .cog-rating-scale table thead th span {
      min-width: 75px !important;
      display: inline-block !important;
    }
  }
`;

const IntroSection = ({ fields }) => {
  const introCopyRef = useRef();

  useEffect(() => {
    var script = null;
    if (fields.name == 'partner-performance-review') {
      script = document.createElement('script');
      script.src = 'https://www.cognitoforms.com/f/seamless.js';
      script.setAttribute('data-key', 'gnr-zrblwkq2lRigxRVNvA');
      script.setAttribute('data-form', '1344');

      introCopyRef.current.appendChild(script);
    }
    return () => {
      if (script && introCopyRef.current) {
        introCopyRef.current.removeChild(script);
      }
    };
  }, [fields]);

  return (
    <StyledIntro className="intro" fields={fields}>
      <div className="mobile moose" />
      <div ref={introCopyRef} className="intro-copy" dangerouslySetInnerHTML={{ __html: fields.intro1 }} />
    </StyledIntro>
  );
};

export default IntroSection;
