import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import useScrollAnimation from '../utils/use-scrollanimation2';
import { useSpring, animated as a } from 'react-spring';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import ky from 'ky';

const StyledDiv = styled.div`
  margin: 185px 0;
  @media (max-width: 900px) {
    margin: 55px 0;
  }
  & .row {
    max-width: 1280px;
    font-family: 'Oswald';
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 112px;
    .address-block {
      flex: 1 0 auto;
      max-width: 756px;
      position: relative;
      color: black;
      .panel {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(24, 138, 226, 0.8); //TODO: Non-standard BLUE
        z-index: 1;
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 57px;
        box-sizing: border-box;
        @media (max-width: 450px) {
          padding: 0 20px !important;
        }
      }
      h2 {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 1.4px;
        margin: 0;
        @media (max-width: 450px) {
          font-size: 1.8rem;
        }
      }
      p {
        font-size: 1.3rem;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0;
        margin-bottom: 0.5rem;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
    .addl-info {
      width: 40%;
      text-align: center;
      h2 {
        color: #009bce;
        width: 215px;
        border-bottom: 1px solid #009bce;
        margin: 0 auto;
        font-weight: 400;
        letter-spacing: 1.4px;
        line-height: 2.1;
      }
      p {
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 1px;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;
      margin-bottom: 0;
      .addl-info {
        margin-top: 30px;
      }
      div {
        width: 100%;
      }
    }
    @media (max-width: 600px) {
      .address-block {
        .panel {
          transform: none !important;
          width: 100%;
          padding-right: 57px;
          .content-wrap {
            text-align: center;
          }
        }
      }
      .addl-info {
        width: 100%;
      }
    }
  }
`;

const StyledImg = styled(GatsbyImage)`
  position: absolute !important;
  width: 524px;
  right: 0;
  margin-top: 86px;
  z-index: -1;
`;

const StyledForm = styled.form`
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px 20px 0;
  display: ${(props) => (props.formstate === 'READY' ? 'grid' : 'none')};
  grid-template-columns: 1fr 1fr;
  .error {
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1rem;
    line-height: 1.25;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    max-height: 60px;
  }
  .input-wrapper {
    display: inline;
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    input[type='text'] {
      width: 100%;
    }
    &:nth-of-type(1),
    &:nth-of-type(3) {
      input[type='text'] {
        width: 96%;
      }
    }
    @media only screen and (max-width: 600px) {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        input[type='text'] {
          width: 100%;
        }
      }
    }
  }
  input[type='text'] {
    max-width: 100%;
    width: 48%;
    padding: 1rem;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.9px;
    background-color: #00a3d6; //TODO: non-standard BLUE
    color: white;
    border: none;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    border-radius: 0px;
  }
  input[name='name'],
  input[name='email'] {
    margin-right: 4%;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    input[name='name'],
    input[name='email'] {
      margin-right: 0;
    }
    input[type='text'] {
      width: 100%;
    }
  }
  textarea {
    width: 100%;
    padding: 1rem;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.9px;
    height: 7rem;
    border: none;
    background-color: #00a3d6; //TODO: non-standard BLUE
    color: white;
    box-sizing: border-box;
    border-radius: 0px;
    grid-column: 1/3;
    @media only screen and (max-width: 600px) {
      grid-column: 1;
    }
  }
  input[type='text']::placeholder,
  textarea::placeholder {
    color: white;
    opacity: 0.6;
  }
  input[type='text']:placeholder-shown,
  textarea:placeholder-shown {
    text-transform: uppercase;
  }
  input[type='submit'] {
    font-family: 'Oswald';
    display: block;
    margin: 2rem auto;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    background-color: black;
    border: 1px solid #00a3d6;
    color: #00a3d6;
    -webkit-appearance: none;
    border-radius: 0px;
    background: transparent;
    grid-column: 1/3;
    &:hover {
      text-decoration: underline;
      outline: 1px solid #00a3d6;
    }
    @media only screen and (max-width: 600px) {
      grid-column: 1;
    }
  }
`;

const FormGroup = styled.div`
  background: white;
  padding-bottom: 5rem;
  padding-top: 80px;
  color: black;
  h1.formheader {
    font-family: 'Oswald';
    font-size: 1.9rem;
    line-height: 1.6;
    margin: 0px auto 30px;
    color: rgb(0, 155, 206);
    font-weight: 400;
    letter-spacing: 1.4px;
    transition: all 200ms linear 0s;
    max-width: 825px;
    padding-top: 80px;
  }
  p {
    margin: 0 auto 15px;
    padding: 0 30px;
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1.25rem;
    line-height: 1.55;
    max-width: 825px;
  }
  .sending,
  .success,
  .fail {
    text-align: center;
  }

  .sending {
    animation: scalebounce 2400ms ease-in-out 0s infinite, fadeintext 400ms linear forwards;
  }

  @keyframes scalebounce {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0.7);
    }
  }
`;

const WherePage = ({ fields }) => {
  const [panelPos1, setPanelPos1] = useSpring(() => ({ transform: 'translateX(100%)' }));
  const contactForm = useRef();
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formState, setFormState] = useState('READY');

  const panel1Move = (item) => {
    if (typeof item.scrollPos !== 'undefined') {
      setPanelPos1.start({
        transform: 'translateX(' + interpolateAndClamp([0, 1], [100, 0], item.scrollPct) + '%)',
      });
    }
  };

  //useScrollAnimation : manages firing an update function on scroll, does NOT trigger renders on value changes, it is up to you to do so if needed, within the update callback e.g. by calling setState()
  const [dispatch] = useScrollAnimation();
  useEffect(() => {
    //set an initial value
    dispatch({
      type: 'registerItem',
      start: 515,
      end: 965,
      id: 'panel1-pos',
      onUpdate: panel1Move,
    });
  }, [dispatch]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation(); //Need to stop propagation to prevent the GA4 enhanced measurement from firing its own form_submit event
      window.dataLayer = window.dataLayer || [];

      const name = contactForm.current.querySelector('input[name=name]');
      const phone = contactForm.current.querySelector('input[name=phone]');
      const email = contactForm.current.querySelector('input[name=email]');
      const business = contactForm.current.querySelector('input[name=business]');
      const message = contactForm.current.querySelector('textarea[name=message]');

      let validationErrors = false;

      if (name.value.length === 0) {
        setNameError('Please provide your name.');
        validationErrors = true;
      } else if (name.value.split(' ').length < 2) {
        setNameError('Please provide your first and last name.');
        validationErrors = true;
      } else {
        setNameError(false);
      }

      if (phone.value.length === 0) {
        setPhoneError('Please provide your phone number.');
        validationErrors = true;
      } else {
        setPhoneError(false);
      }

      if (email.value.length === 0) {
        setEmailError('Please provide your email address.');
        validationErrors = true;
      } else {
        setEmailError(false);
      }

      if (validationErrors) {
        window.dataLayer.push({
          event: 'form_error',
          form_name: 'contact',
          form_event_source: 'Contact Us Page',
          error_type: 'Field validation error',
        });
        return false;
      }

      const nameparts = name.value.split(' ');
      const firstname = nameparts[0];
      const lastname = nameparts[1];
      contactForm.current.querySelector('input[name=firstname]').value = firstname;
      contactForm.current.querySelector('input[name=lastname]').value = lastname;
      setFormState('SENDING');

      (async () => {
        try {
          const result = await ky
            .post('https://usebasin.com/f/8d56d45aa45a.json', {
              json: {
                name: name.value,
                phone: phone.value,
                email: email.value,
                business: business.value,
                message: message.value,
              },
            })
            .json();

          // console.log(result);
          window.dataLayer.push({ event: 'form_submit', form_name: 'contact', form_event_source: 'Contact Us Page' });

          if (result.success === true) {
            setFormState('SUCCESS');
          } else {
            window.dataLayer.push({
              event: 'form_error',
              form_name: 'contact',
              form_event_source: 'Contact Us Page',
              error_type: 'Basin acknowledgement is not success',
            });
            setFormState('FAIL');
          }
        } catch (error) {
          window.dataLayer.push({
            event: 'form_error',
            form_name: 'contact',
            form_event_source: 'Contact Us Page',
            error_type: 'AJAX Post failure',
          });
          setFormState('FAIL');
          return;
        }
      })();
    },
    [contactForm]
  );

  return (
    <>
      <FormGroup>
        {/* <h1 className="formheader">A unique, comfortable environment is an important part of what we offer our clients and business partners. </h1> */}
        <div className="formcontent">
          <p>
            As much pride as we take in our team, what makes Element special is what we do to help our clients stand
            out: provide services that marry marketing and sales combined with a down-to-earth culture and honest
            communication. Call us, email us, or just fill out the form and click submit. We'll be in touch soon!
          </p>
        </div>
        <StyledForm
          method="POST"
          action=""
          formstate={formState}
          id="contact-form"
          ref={contactForm}
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form" value="contact" />
          <input type="hidden" name="firstname" />
          <input type="hidden" name="lastname" />
          <div className="input-wrapper">
            {nameError !== false ? <div className="error">{nameError}</div> : null}
            <input type="text" size="35" name="name" placeholder="Name" />
          </div>
          <div className="input-wrapper">
            {phoneError !== false ? <div className="error">{phoneError}</div> : null}
            <input type="text" size="35" name="phone" placeholder="Phone" />
          </div>
          <div className="input-wrapper">
            {emailError !== false ? <div className="error">{emailError}</div> : null}
            <input type="text" size="35" name="email" placeholder="Email" />
          </div>
          <div className="input-wrapper">
            <input type="text" size="35" name="business" placeholder="Business" />
          </div>
          <textarea rows="8" cols="25" name="message" placeholder="Message" />
          <input type="submit" value="Submit" />
        </StyledForm>
        {formState === 'SENDING' && (
          <div className="sending">
            <p>Sending...</p>
          </div>
        )}
        {formState === 'SUCCESS' && (
          <div className="success">
            <p>Your message has been sent! We will be contacting you shortly.</p>
          </div>
        )}
        {formState === 'FAIL' && (
          <div className="fail">
            <p>Uh oh! Something went wrong. Please try again later, or give us a call at 920-983-9700.</p>
          </div>
        )}
      </FormGroup>
      {fields.smoke_image && (
        <StyledImg
          className="smoke"
          image={fields.smoke_image.childImageSharp.gatsbyImageData}
          alt={fields.smoke_alt}
        />
      )}

      <StyledDiv>
        <div className="row">
          <div className="address-block">
            <a.div className="panel" style={panelPos1}>
              <div className="content-wrap">
                <h2>De Pere</h2>
                <p>
                  2081 Profit Place
                  <br />
                  De Pere, WI 54115
                </p>
                <p>
                  <a href="https://goo.gl/maps/R2AQsZTrtYFcgqzy7">Directions</a>
                </p>
              </div>
            </a.div>
            <GatsbyImage image={fields.location_bg1.childImageSharp.gatsbyImageData} alt={fields.location_1_alt} />
          </div>
          <div className="addl-info">
            <h2>Phone</h2>
            <p>
              <a href="tel:9209839700">920.983.9700</a>
            </p>
            <h2>E-mail</h2>
            <p>
              <a href="mailto:info@goelement.com">info@goelement.com</a>
            </p>
          </div>
        </div>
      </StyledDiv>
    </>
  );
};

export default WherePage;
