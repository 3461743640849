import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const StyledHeroText = styled.div`
  font-family: 'Oswald';
  font-weight: 400;
  color: #fff;
  text-align: center;
  position: absolute;
  top: ${(props) => 50 + (props.titleOffsetPct || 0)}%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 9;
  box-sizing: border-box;
  padding: 0 15px;
  /* margin-top: 110px; */

  & h1 {
    color: #fff;
    font-weight: 400;
    font-size: 4.75rem;
    letter-spacing: 2.8px;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1.125rem;
    flex: 1 0 100%;
    text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px, #141a1f 0px 0px 0.5em;
    margin-top: 0;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  }

  & p {
    font-weight: 300;
    letter-spacing: 2.1px;
    font-size: 1.9rem;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 1.125rem;
    flex: 1 0 100%;
    text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 2px, #141a1f 0px 0px 0.5em;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
  }
`;

const StyledLabel = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 50;
  padding: 7px 35px;
  background-color: #0095c8;
  font-family: 'Oswald';
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 1.5rem;
  box-shadow: 0px 0px 10px 2px rgba(53, 53, 53, 0.7);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  &.case-study {
    background-color: #ed8b00;
  }
  @media (max-width: 690px) {
    top: 10px;
    font-size: 1rem;
  }
`;

const StyledHero = styled.section`
  @media only screen and (min-width: 899px) {
    margin-top: 110px;
  }
  .innerWrap {
    width: 100%;
  }

  &.agency-careers,
  &.partner-performance-review {
    h1 {
      font-size: 48px;
      line-height: 52px;
      font-weight: 200;
      text-shadow: none;
      letter-spacing: normal;
    }
    p {
      font-size: 32px;
      line-height: 36px;
      font-weight: 200;
      text-shadow: none;
      letter-spacing: 0.5px;
    }
    .cta {
      margin-top: 60px;
    }
    @media (max-width: 1040px) {
      .cta {
        margin-top: 25px;
      }
    }
    @media (max-width: 740px) {
      .cta {
        margin-top: 15px;
      }
    }
  }

  ${(props) =>
    props.isBlog &&
    css`
      .innerWrap {
        max-width: 1150px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
      }
      .innerWrap::before {
        content: '';
        background: linear-gradient(90deg, black, black 8%, transparent 100%);
        width: 277px;
        height: 110%;
        position: absolute;
        z-index: 13;
        display: block;
        top: -20px;
        left: -26px;
        /* filter: blur(14px); */
      }
      .innerWrap::after {
        content: '';
        background: linear-gradient(-90deg, black, black 8%, transparent 100%);
        width: 277px;
        height: 110%;
        position: absolute;
        z-index: 13;
        display: block;
        top: -20px;
        right: -26px;
        /* filter: blur(14px); */
      }
      @media (max-width: 900px) {
        .innerWrap::before {
          background: linear-gradient(90deg, black 8%, rgba(0, 0, 0, 0%) 100%);
          left: -50px;
        }
        .innerWrap::after {
          background: linear-gradient(-90deg, black 8%, rgba(0, 0, 0, 0%) 100%);
          right: -50px;
        }
      }
      @media (max-width: 690px) {
        .innerWrap::before {
          background: linear-gradient(90deg, black 2%, rgba(0, 0, 0, 0%) 100%);
          left: -100px;
        }
        .innerWrap::after {
          background: linear-gradient(-90deg, black 2%, rgba(0, 0, 0, 0%) 100%);
          right: -100px;
        }
      }
    `}

  .gatsby-image-wrapper {
    max-height: 500px;
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 1150px) {
    &.featured-hero,
    &.case-hero {
      .gatsby-image-wrapper {
        height: 300px;
      }
    }
    &.digital-dashboard {
      .gatsby-image-wrapper {
        height: auto;
      }
    }
  }
  @media (max-width: 1040px) {
    .gatsby-image-wrapper {
      height: 300px;
    }
    &.digital-dashboard {
      .gatsby-image-wrapper {
        height: auto;
      }
    }
  }
`;

const sectionStyle = { position: 'relative' };

const Hero = (props) => {
  let heroImgStyle = { objectPosition: 'center center' };

  return (
    <StyledHero className={props.name} style={sectionStyle} name={props.name} isBlog={props.isBlog}>
      <div className="innerWrap">
        {props.heroImg && (
          <GatsbyImage image={props.heroImg} imgStyle={heroImgStyle} alt={props.alt_text ? props.alt_text : 'hero'} />
        )}
        {(props.title1 || props.title2) && (
          <StyledHeroText titleOffsetPct={props.titleOffsetPct}>
            <h1>{props.title1}</h1>
            {props.title2 && <p>{props.title2}</p>}
            {/* Used only on Careers page */}
            {props.cta && (
              <Link className="cta" to="#openopp">
                {props.cta}
              </Link>
            )}
          </StyledHeroText>
        )}
      </div>
      {props.label && <StyledLabel className={props.labelClass}>{props.label}</StyledLabel>}
    </StyledHero>
  );
};

export default Hero;
