import React, { useState, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import PortalToTop from '../components/PortalToTop';
import CrewDialog from '../components/CrewDialog';

import { useStaticQuery, graphql } from 'gatsby';
import spriteMask from '../../src/img/urban-sprite.png';

const StyledCrewGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  margin: 0 10%;
  @media (max-width: 620px) {
    margin: 0;
  }
  @media (max-width: 680px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .item {
    position: relative;
    cursor: pointer;
    z-index: 1;
    .clrimage {
      z-index: -5;
    }
    .bwimage {
      z-index: -6;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .hoverName {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.93);
    border: 1px solid #00bcd4;
    color: #fff;
    padding: 20px;
    z-index: 99;
    box-sizing: border-box;
    text-align: center;
    .name {
      font-size: 20px;
      font-family: 'Oswald';
      color: #00a4d9;
      font-weight: 200;
    }
    .role {
      font-size: 14px;
      font-family: 'Oswald';
      text-transform: uppercase;
      font-weight: 200;
    }
  }

  .item.hide .clrimage.gatsby-image-wrapper {
    mask: url(${spriteMask});
    mask-size: 3000% 100%;
    animation: mask-play 1000ms steps(29) forwards;
  }

  @keyframes mask-play {
    0% {
      mask-position: 0% 0;
    }
    100% {
      mask-position: 100% 0;
    }
  }
`;

const StyledHeadline = styled.h2`
  margin-bottom: 50px;
  font-family: 'Oswald';
  text-transform: uppercase;
  letter-spacing: 1.6px;
  margin: 8rem 0 52px;
  font-size: 4rem;
  line-height: 62px;
  color: white;
  font-weight: 400;
  transition: all 200ms linear;
  text-align: center;
  @media (max-width: 600px) {
    font-size: 3rem;
  }
`;

const Crew = () => {
  const [hovered, setHovered] = useState(null);
  const [active, setActive] = useState(null);
  const [top, setTop] = useState(0);
  let offset = 90;
  useEffect(() => {
    let og_width = window.width;
    let resizeListener = () => {
      if (window.width != og_width) {
        setTop(window.scrollY + offset);
        og_width = window.width;
        if (window.width < 901) {
          offset = 105;
        }
      }
    };
    window.addEventListener('resize', resizeListener);
    //initialize the top state
    resizeListener();
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const data = useStaticQuery(
    graphql`
      query {
        allCrewJson(filter: { active: { eq: true } }) {
          edges {
            node {
              id
              name
              role
              image_bw {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 500)
                }
              }
              image_color {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 500, quality: 99)
                }
              }
              image_casual {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 500, quality: 99)
                }
              }
              questions {
                question
                answer
              }
            }
          }
        }
      }
    `
  );

  return (
    <>
      <StyledHeadline>The Leadership Team</StyledHeadline>
      <StyledCrewGrid style={{ backgroundColor: '#000' }} onMouseLeave={() => setHovered(null)}>
        {data.allCrewJson.edges.map(
          (edge, index) =>
            edge.node.image_color && (
              <>
                <div
                  className={'item' + (index === hovered ? ' hide' : '')}
                  key={index}
                  onMouseEnter={() => setHovered(index)}
                  onClick={(e) => {
                    setActive(index);
                    setHovered(null);
                    setTop(window.scrollY + offset);
                  }}
                >
                  {edge.node.image_bw.childImageSharp ? (
                    <GatsbyImage
                      className="bwimage"
                      style={{ position: 'absolute' }}
                      image={edge.node.image_bw.childImageSharp.gatsbyImageData}
                      alt={edge.node.name + ' - ' + edge.node.role}
                    />
                  ) : (
                    ''
                  )}
                  {edge.node.image_color.childImageSharp ? (
                    <GatsbyImage
                      className="clrimage"
                      image={edge.node.image_color.childImageSharp.gatsbyImageData}
                      alt={edge.node.name + ' - ' + edge.node.role}
                    />
                  ) : (
                    ''
                  )}
                  {active === index && (
                    <PortalToTop>
                      <CrewDialog
                        top={top}
                        fields={edge.node}
                        onChangeActive={(active) => {
                          setActive(active);
                        }}
                      />
                    </PortalToTop>
                  )}
                </div>
                {hovered === index && (active === -1 || active === null) && (
                  <div className="hoverName">
                    <div className="name">{edge.node.name}</div>
                    <div className="role">{edge.node.role}</div>
                  </div>
                )}
              </>
            )
        )}
      </StyledCrewGrid>
    </>
  );
};

export default Crew;
