import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import useScrollAnimation from '../utils/use-scrollanimation2';
import { useSpring, animated as a } from 'react-spring';
import interpolateAndClamp from '../utils/interpolate-and-clamp';
import ky from 'ky';
import ReactDOM from 'react-dom';

const StyledDiv = styled.div`
  margin: 185px 0;
  @media (max-width: 900px) {
    margin: 55px 0;
  }
  & .row {
    max-width: 1280px;
    font-family: 'Oswald';
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 112px;
    .address-block {
      flex: 1 0 auto;
      max-width: 756px;
      position: relative;
      color: black;
      .panel {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(24, 138, 226, 0.8); //TODO: Non-standard BLUE
        z-index: 1;
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 57px;
        box-sizing: border-box;
        @media (max-width: 450px) {
          padding: 0 20px !important;
        }
      }
      h2 {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 1.4px;
        margin: 0;
        @media (max-width: 450px) {
          font-size: 1.8rem;
        }
      }
      p {
        font-size: 1.3rem;
        line-height: 1.2;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0;
        margin-bottom: 0.5rem;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
    .addl-info {
      width: 40%;
      text-align: center;
      h2 {
        color: #009bce;
        width: 215px;
        border-bottom: 1px solid #009bce;
        margin: 0 auto;
        font-weight: 400;
        letter-spacing: 1.4px;
        line-height: 2.1;
      }
      p {
        font-size: 1.3rem;
        line-height: 1;
        letter-spacing: 1px;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
    @media (max-width: 900px) {
      flex-direction: column;
      margin-bottom: 0;
      .addl-info {
        margin-top: 30px;
      }
      div {
        width: 100%;
      }
    }
    @media (max-width: 600px) {
      .address-block {
        .panel {
          transform: none !important;
          width: 100%;
          padding-right: 57px;
          .content-wrap {
            text-align: center;
          }
        }
      }
      .addl-info {
        width: 100%;
      }
    }
  }
`;

const StyledImg = styled(GatsbyImage)`
  position: absolute !important;
  width: 524px;
  right: 0;
  margin-top: 86px;
  z-index: -1;
`;

const StyledForm = styled.form`
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 30px 20px 0;
  display: ${(props) => (props.formstate === 'READY' ? 'block' : 'none')};
  grid-template-columns: 1fr 1fr;
  .error {
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1rem;
    line-height: 1.25;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    max-height: 60px;
    color: #8b0000;
  }
  label,
  fieldset legend {
    font-family: 'Roboto';
    font-weight: 300;
    /* letter-spacing: 1.3px; */
    font-size: 1rem;
    line-height: 1.55;
  }
  fieldset {
    border: 2px solid #00a3d6;
  }
  fieldset legend,
  label {
    font-weight: 500;
  }
  fieldset label {
    font-weight: 300;
  }
  fieldset div {
    margin: 8px auto;
  }
  /* fieldset .other-fill-in {
    display: none;
  } */
  .input-wrapper {
    display: inline;
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 30px auto;
    input[type='text'] {
      width: 100%;
    }
    /* &:nth-of-type(1),
    &:nth-of-type(3) {
      input[type='text'] {
        width: 96%;
      }
    }
    @media only screen and (max-width: 600px) {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        input[type='text'] {
          width: 100%;
        }
      }
    } */
  }
  input[type='text'],
  select {
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.9px;
    background-color: #00a3d6; //TODO: non-standard BLUE
    color: white;
    border: none;
    margin-bottom: 1.5rem;
    box-sizing: border-box;
    border-radius: 0px;
  }
  select option {
    color: #000;
    opacity: 0.3;
  }
  input[name='name'],
  input[name='email'] {
    margin-right: 4%;
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    input[name='name'],
    input[name='email'] {
      margin-right: 0;
    }
    input[type='text'] {
      width: 100%;
    }
  }
  textarea {
    width: 100%;
    padding: 1rem;
    font-family: 'Roboto';
    font-size: 1.1rem;
    font-weight: 300;
    letter-spacing: 0.9px;
    height: 7rem;
    border: none;
    background-color: #00a3d6; //TODO: non-standard BLUE
    color: white;
    box-sizing: border-box;
    border-radius: 0px;
    grid-column: 1/3;
    @media only screen and (max-width: 600px) {
      grid-column: 1;
    }
  }
  .other-fill-in {
    display: none;
  }
  input[type='text']::placeholder,
  textarea::placeholder {
    color: white;
    opacity: 0.6;
  }
  input[type='text']:placeholder-shown,
  textarea:placeholder-shown {
    text-transform: uppercase;
  }
  input[type='submit'] {
    font-family: 'Oswald';
    display: block;
    margin: 2rem auto;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 300;
    letter-spacing: 2px;
    background-color: black;
    border: 1px solid #00a3d6;
    color: #00a3d6;
    -webkit-appearance: none;
    border-radius: 0px;
    background: transparent;
    grid-column: 1/3;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      outline: 1px solid #00a3d6;
    }
    @media only screen and (max-width: 600px) {
      grid-column: 1;
    }
  }
`;

const FormGroup = styled.div`
  background: white;
  padding-bottom: 5rem;
  padding-top: 80px;
  color: black;
  h1.formheader {
    font-family: 'Oswald';
    font-size: 1.9rem;
    line-height: 1.6;
    margin: 0px auto 30px;
    color: rgb(0, 155, 206);
    font-weight: 400;
    letter-spacing: 1.4px;
    transition: all 200ms linear 0s;
    max-width: 825px;
    padding-top: 30px;
    text-align: center;
  }
  p {
    margin: 0 auto 15px;
    padding: 0 30px;
    font-family: 'Roboto';
    font-weight: 300;
    letter-spacing: 1.3px;
    font-size: 1.25rem;
    line-height: 1.55;
    max-width: 610px;
    text-align: center;
  }
  .formcontent,
  .formcontent iframe {
    max-width: 800px;
    margin: 0 auto;
    display: block;
  }
  .formcontent p {
    max-width: 825px;
    text-align: left;
  }
  .sending,
  .success,
  .fail {
    text-align: center;
  }

  .sending {
    animation: scalebounce 2400ms ease-in-out 0s infinite, fadeintext 400ms linear forwards;
  }

  @keyframes scalebounce {
    0% {
      transform: scale(0.7);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(0.7);
    }
  }
`;

const WebsiteRequest = ({ fields, context }) => {
  const [panelPos1, setPanelPos1] = useSpring(() => ({ transform: 'translateX(100%)' }));
  const contactForm = useRef();
  const [nameError, setNameError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [formState, setFormState] = useState('READY');

  const [showOther, setShowOther] = useState(false);

  //   const showOtherClick = () => {
  //     setShowOther(true);
  //     console.log('showOther');
  //   };
  //   const hideOtherClick = () => {
  //     setShowOther(false);
  //     console.log('hideOther');
  //   };

  const ref = React.useRef();

  useEffect(() => {
    function intervalFunc() {
      if (ref.current && ref.current.contentWindow) {
        ref.current.contentWindow.postMessage('getHeight', '*');
      }
    }
    function messageHandler(event) {
      if (event.data.action === 'setHeight') {
        ref.current.style.height = event.data.height + 'px';
      }
      if (event.data.action === 'redirect') {
        window.location.href = event.data.url;
      }
    }

    var intervalHandle = setInterval(intervalFunc, 500);
    window.addEventListener('message', messageHandler);

    return () => {
      clearInterval(intervalHandle);
      window.removeEventListener('message', messageHandler);
    };
  }, [ref]);

  const panel1Move = (item) => {
    if (typeof item.scrollPos !== 'undefined') {
      setPanelPos1.start({
        transform: 'translateX(' + interpolateAndClamp([0, 1], [100, 0], item.scrollPct) + '%)',
      });
    }
  };

  //useScrollAnimation : manages firing an update function on scroll, does NOT trigger renders on value changes, it is up to you to do so if needed, within the update callback e.g. by calling setState()
  const [dispatch] = useScrollAnimation();
  useEffect(() => {
    //set an initial value
    dispatch({
      type: 'registerItem',
      start: 515,
      end: 965,
      id: 'panel1-pos',
      onUpdate: panel1Move,
    });
  }, [dispatch]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation(); //Need to stop propagation to prevent the GA4 enhanced measurement from firing its own form_submit event
      window.dataLayer = window.dataLayer || [];

      const name = contactForm.current.querySelector('input[name=name]');
      const title = contactForm.current.querySelector('input[name=title]');
      const phone = contactForm.current.querySelector('input[name=phone]');
      const email = contactForm.current.querySelector('input[name=email]');
      const business = contactForm.current.querySelector('input[name=business]');
      //   const message = contactForm.current.querySelector('textarea[name=message]');

      let validationErrors = false;

      if (name.value.length === 0) {
        setNameError('Please provide your name.');
        validationErrors = true;
      } else if (name.value.split(' ').length < 2) {
        setNameError('Please provide your first and last name.');
        validationErrors = true;
      } else {
        setNameError(false);
      }

      if (title.value.length === 0) {
        setTitleError('Please provide your title.');
        validationErrors = true;
      } else {
        setTitleError(false);
      }

      if (phone.value.length === 0) {
        setPhoneError('Please provide your phone number.');
        validationErrors = true;
      } else {
        setPhoneError(false);
      }

      if (email.value.length === 0) {
        setEmailError('Please provide your email address.');
        validationErrors = true;
      } else {
        setEmailError(false);
      }

      if (validationErrors) {
        window.dataLayer.push({
          event: 'form_error',
          form_name: 'website_request',
          form_event_source: 'Website Request Page',
          error_type: 'Field validation error',
        });
        return false;
      }

      const nameparts = name.value.split(' ');
      const firstname = nameparts[0];
      const lastname = nameparts[1];
      contactForm.current.querySelector('input[name=firstname]').value = firstname;
      contactForm.current.querySelector('input[name=lastname]').value = lastname;
      setFormState('SENDING');

      (async () => {
        try {
          const result = await ky
            .post('https://usebasin.com/f/956019534e4b.json', {
              json: {
                name: name.value,
                phone: phone.value,
                email: email.value,
                business: business.value,
              },
            })
            .json();

          // console.log(result);
          window.dataLayer.push({ event: 'form_submit', form_name: 'contact', form_event_source: 'Contact Us Page' });

          if (result.success === true) {
            setFormState('SUCCESS');
          } else {
            window.dataLayer.push({
              event: 'form_error',
              form_name: 'contact',
              form_event_source: 'Contact Us Page',
              error_type: 'Basin acknowledgement is not success',
            });
            setFormState('FAIL');
          }
        } catch (error) {
          window.dataLayer.push({
            event: 'form_error',
            form_name: 'contact',
            form_event_source: 'Contact Us Page',
            error_type: 'AJAX Post failure',
          });
          setFormState('FAIL');
          return;
        }
      })();
    },
    [contactForm]
  );

  return (
    <>
      {context.isThankYouPage ? (
        <FormGroup>
          <div className="formcontent">
            <h1 className="formheader">Thank you for your submission!</h1>
            <p>We'll be in touch soon to discuss your project and provide a quote.</p>
          </div>
        </FormGroup>
      ) : (
        <FormGroup>
          {/* <h1 className="formheader">A unique, comfortable environment is an important part of what we offer our clients and business partners. </h1> */}
          <div className="formcontent">
            <p>
              Whether you're looking to give your website a refresh or building a new one from the ground up, our expert
              developers have your back. Take a minute to complete the website quote request below and we'll get in
              touch to discuss your project.
            </p>
            <iframe
              src="https://usebasin.com/form/956019534e4b/view/5baee074b37f?iframe=true"
              ref={ref}
              width="100%"
              height="100px"
              style={{
                maxWidth: 800,
                width: '100%',
                overflow: 'auto',
                border: 'none',
              }}
            ></iframe>
          </div>
          {/* <StyledForm
          method="POST"
          action=""
          formstate={formState}
          id="contact-form"
          ref={contactForm}
          onSubmit={handleSubmit}
        >
          <div className="input-wrapper">
            <label for="current-website">What is your current website URL? (leave blank if you do not have one):</label>
            <input type="text" name="current-website" placeholder="Website URL" />
          </div>
          <div className="input-wrapper">
            <fieldset id="project-scope">
              <legend>What type of website project are you looking to complete?</legend>
              <div>
                <input type="radio" id="new-replacement" name="project-scope" value="new-replacement" />
                <label for="new-replacement">New or Replacement</label>
              </div>
              <div>
                <input type="radio" id="addition" name="project-scope" value="addition" />
                <label for="addition">Addition</label>
              </div>
              <div>
                <input type="radio" id="revamp" name="project-scope" value="revamp" />
                <label for="revamp">Revamp</label>
              </div>
              <div>
                <input
                  type="radio"
                  //   onFocus={showOtherClick}
                  //   onBlur={hideOtherClick}
                  id="scope-other-radio"
                  name="project-scope"
                  value="other"
                />
                <label for="scope-other-radio">Other</label>
                {showOther && (
                  <input
                    type="text"
                    className="other-fill-in"
                    id="scope-other-radio"
                    name="project-scope"
                    placeholder="Other"
                  />
                )}
              </div>
            </fieldset>
          </div>
          <div className="input-wrapper">
            <fieldset id="primary-function">
              <legend>What is the primary function of your website?</legend>
              <div>
                <input type="radio" id="info-educate" name="primary-function" value="Provide information and educate" />
                <label for="info-educate">Provide information and educate</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="generate-leads"
                  name="primary-function"
                  value="Generate sales qualified leads"
                />
                <label for="generate-leads">Generate sales qualified leads</label>
              </div>
              <div>
                <input type="radio" id="direct-sales" name="primary-function" value="Drive direct to consumer sales" />
                <label for="direct-sales">Drive direct to consumer sales</label>
              </div>
              <div>
                <input type="radio" id="function-other-radio" name="primary-function" value="other" />
                <label for="function-other-radio">Other</label>
                <input
                  type="text"
                  className="other-fill-in"
                  id="function-other-radio"
                  name="primary-function"
                  placeholder="Other"
                />
              </div>
            </fieldset>
          </div>
          <div className="input-wrapper">
            <label for="features">
              Please describe the primary goal or reason you are looking to complete a website project:
            </label>
            <input type="text" name="features" placeholder="Website goal" />
          </div>

          <div className="input-wrapper">
            <label for="website-completion">When would you like the website project to be completed?</label>

            <select name="website-completion">
              <option value=""></option>
              <option value="6 weeks or less">6 weeks or less</option>
              <option value="6-12 weeks">6-12 weeks</option>
              <option value="12-26 Weeks">12-26 Weeks</option>
              <option value="26 weeks +">26 weeks +</option>
              <option value="No timeline">No timeline</option>
            </select>
          </div>

          <div className="input-wrapper">
            <label for="pages">Estimated number of pages on your new website project</label>
            <select name="pages">
              <option value=""></option>
              <option value="Less than 10">Less than 10</option>
              <option value="10-20">10-20</option>
              <option value="20-30">20-30</option>
              <option value="Over 30">Over 30</option>
              <option value="Unsure">Unsure</option>
            </select>
          </div>

          <div className="input-wrapper">
            <label for="content-type">
              Describe the type of content that will be published on your website (e.g. 15 unique pages describing the
              company and our services/solutions/markets, a blog with ~40 blog posts, 30 product pages, 5 project
              profiles, a newsroom with ~15 press releases, and 4 pieces of gated content):
            </label>
            <textarea rows="8" cols="25" name="content-type" placeholder="Describe your content type" />
          </div>

          <div className="input-wrapper">
            <fieldset id="provided-content">
              <legend>Will you provide the content for your new website project?</legend>
              <div>
                <input
                  type="radio"
                  id="client-provided"
                  name="provided-content"
                  value="I will provide all the copy in its final state "
                />
                <label for="client-provided">I will provide all the copy in its final state </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="element-proof"
                  name="provided-content"
                  value="I will provide the copy but will want Element’s copy and UX experts to proof and provide recommended edits"
                />
                <label for="element-proof">
                  I will provide the copy but will want Element’s copy and UX experts to proof and provide recommended
                  edits
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="rough-guidelines"
                  name="provided-content"
                  value="I will provide rough guidelines but will need Element’s copywriters to write the content"
                />
                <label for="rough-guidelines">
                  I will provide rough guidelines but will need Element’s copywriters to write the content
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="existing-copy"
                  name="provided-content"
                  value="Use the copy from my existing website as is"
                />
                <label for="existing-copy">Use the copy from my existing website as is</label>
              </div>
              <div>
                <input type="radio" id="copy-other-input" name="provided-content" value="other" />
                <label for="copy-other-input">Other</label>
                <input
                  type="text"
                  className="other-fill-in"
                  id="copy-other-input"
                  name="provided-content"
                  placeholder="Other"
                />
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <fieldset id="electronic-images">
              <legend>Do you have electronic files of images and photography for your new website? </legend>
              <div>
                <input
                  type="radio"
                  id="provided-images"
                  name="electronic-images"
                  value="Yes, I can provide web-ready images (high quality, low-file size png or jpg images)"
                />
                <label for="provided-images">
                  Yes, I can provide web-ready images (high quality, low-file size png or jpg images)
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="element-images"
                  name="electronic-images"
                  value="No, I need Element to capture photos and create graphics for my website"
                />
                <label for="element-images">
                  No, I need Element to capture photos and create graphics for my website
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="some-images-provided"
                  name="electronic-images"
                  value="I have some image assets but need Element to source additional stock photos for my website"
                />
                <label for="some-images-provided">
                  I have some image assets but need Element to source additional stock photos for my website
                </label>
              </div>
              <div>
                <input type="radio" id="image-source-other" name="electronic-images" value="other" />
                <label for="image-source-other">Other</label>
                <input
                  type="text"
                  className="other-fill-in"
                  id="image-source-other"
                  name="electronic-images"
                  placeholder="Other"
                />
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <fieldset id="seo-prioritization">
              <legend>How do you want to prioritize SEO in your new website project?</legend>
              <div>
                <input
                  type="radio"
                  id="comprehensive-seo"
                  name="seo-prioritization"
                  value="I need a comprehensive SEO strategy to inform the entire process (I want an SEO analysis performed on my current site, new keyword research, and competitive analysis performed up front to inform the entire website/content strategy)"
                />
                <label for="comprehensive-seo">
                  I need a comprehensive SEO strategy to inform the entire process (I want an SEO analysis performed on
                  my current site, new keyword research, and competitive analysis performed up front to inform the
                  entire website/content strategy)
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="basic-seo"
                  name="seo-prioritization"
                  value="I want to keep SEO in mind throughout the process, so we accomplish SEO best practices but don’t need a full SEO strategy up front (custom page titles, meta descriptions, optimized image sizes with alt text, relevant headers, and link text, etc. should be in place by the time we go live)"
                />
                <label for="basic-seo">
                  I want to keep SEO in mind throughout the process, so we accomplish SEO best practices but don’t need
                  a full SEO strategy up front (custom page titles, meta descriptions, optimized image sizes with alt
                  text, relevant headers, and link text, etc. should be in place by the time we go live)
                </label>
              </div>
              <div>
                <input type="radio" id="seo-other" name="seo-prioritization" value="other" />
                <label for="seo-other">Other</label>
                <input
                  type="text"
                  className="other-fill-in"
                  id="seo-other"
                  name="seo-prioritization"
                  placeholder="Other"
                />
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <fieldset id="preferred-cms">
              <legend>Do you have a preferred CMS (Content Management System)?</legend>
              <div>
                <input type="checkbox" id="wordpress" name="preferred-cms" value="WordPress" />
                <label for="wordpress">WordPress</label>
              </div>
              <div>
                <input type="checkbox" id="shopify" name="preferred-cms" value="Shopify" />
                <label for="shopify">Shopify</label>
              </div>
              <div>
                <input type="checkbox" id="drupal" name="preferred-cms" value="Drupal" />
                <label for="drupal">Drupal</label>
              </div>
              <div>
                <input type="checkbox" id="kentico" name="preferred-cms" value="Kentico" />
                <label for="kentico">Kentico</label>
              </div>
              <div>
                <input type="checkbox" id="hubspot" name="preferred-cms" value="HubSpot" />
                <label for="hubspot">HubSpot</label>
              </div>
              <div>
                <input type="checkbox" id="joomla" name="preferred-cms" value="Joomla" />
                <label for="joomla">Joomla</label>
              </div>
              <div>
                <input type="checkbox" id="magento" name="preferred-cms" value="Magento" />
                <label for="magento">Magento</label>
              </div>
              <div>
                <input type="checkbox" id="no-preference" name="preferred-cms" value="No Preference" />
                <label for="no-preference">No Preference</label>
              </div>
              <div>
                <input type="checkbox" id="cms-other" name="preferred-cms" value="other" />
                <label for="cms-other">Other</label>
                <input type="text" className="other-fill-in" id="cms-other" name="preferred-cms" placeholder="Other" />
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <label for="third-party">
              Will there need to be any third-party software integrated into the website? If yes, please explain what
              software will need to be integrated.
            </label>
            <textarea rows="8" cols="25" name="third-party" placeholder="List any third-party required software" />
          </div>

          <div className="input-wrapper">
            <label for="unique-functionality">
              Is there a need for customized or unique functionality built into your website?
            </label>
            <textarea
              rows="8"
              cols="25"
              name="unique-functionality"
              placeholder="Describe any unique functionality needed"
            />
          </div>

          <div className="input-wrapper">
            <fieldset id="ada-compliant">
              <legend>It is necessary for my website to be compliant with ADA standards.</legend>
              <div>
                <input type="radio" id="yes-ada" name="ada-compliant" value="Yes" />
                <label for="yes-ada">Yes</label>
              </div>
              <div>
                <input type="radio" id="no-ada" name="ada-compliant" value="No" />
                <label for="no-ada">No</label>
              </div>
              <div>
                <input type="radio" id="unsure-ada" name="ada-compliant" value="Unsure" />
                <label for="unsure-ada">Unsure</label>
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <fieldset id="gdpr-compliant">
              <legend>It is necessary for my website to be compliant with CCPA and/or GDPR regulations.</legend>
              <div>
                <input type="radio" id="yes-ada" name="gdpr-compliant" value="Yes" />
                <label for="yes-ada">Yes</label>
              </div>
              <div>
                <input type="radio" id="no-ada" name="gdpr-compliant" value="No" />
                <label for="no-ada">No</label>
              </div>
              <div>
                <input type="radio" id="unsure-ada" name="gdpr-compliant" value="Unsure" />
                <label for="unsure-ada">Unsure</label>
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <fieldset id="future-updates">
              <legend>
                After the website is complete, will you and your team need access to edit all content and publish new
                content or will you rely on Element for future website updates?
              </legend>
              <div>
                <input
                  type="radio"
                  id="internal-updates"
                  name="future-updates"
                  value="My team will handle all future updates internally"
                />
                <label for="internal-updates">My team will handle all future updates internally</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="element-support"
                  name="future-updates"
                  value="We want the ability to publish new content and make simple edits to existing content but will rely on Element to implement larger web updates"
                />
                <label for="element-support">
                  We want the ability to publish new content and make simple edits to existing content but will rely on
                  Element to implement larger web updates
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="all-element"
                  name="future-updates"
                  value="We’ll need Element to handle all website updates going forward"
                />
                <label for="all-element">We’ll need Element to handle all website updates going forward</label>
              </div>
            </fieldset>
          </div>

          <div className="input-wrapper">
            <label for="budget">What is your budget range for this project?</label>
            <select name="budget">
              <option value=""></option>
              <option value="Less than $15,000">Less than $15,000</option>
              <option value="$15,000 - $25,000">$15,000 - $25,000</option>
              <option value="$25,000 - $40,000">$25,000 - $40,000</option>
              <option value="$40,000 - $60,000">$40,000 - $60,000</option>
              <option value="$60,000 - $80,000">$60,000 - $80,000</option>
              <option value="More than $80,000">More than $80,000</option>
              <option value="Unsure">Unsure</option>
            </select>
          </div>

          <input type="hidden" name="form" value="website-request" />
          <input type="hidden" name="firstname" />
          <input type="hidden" name="lastname" />
          <div className="input-wrapper">
            {nameError !== false ? <div className="error">{nameError}</div> : null}
            <input type="text" size="35" name="name" placeholder="Name" />
          </div>
          <div className="input-wrapper">
            {titleError !== false ? <div className="error">{titleError}</div> : null}
            <input type="text" size="35" name="title" placeholder="Title" />
          </div>
          <div className="input-wrapper">
            {phoneError !== false ? <div className="error">{phoneError}</div> : null}
            <input type="text" size="35" name="phone" placeholder="Phone" />
          </div>
          <div className="input-wrapper">
            {emailError !== false ? <div className="error">{emailError}</div> : null}
            <input type="text" size="35" name="email" placeholder="Email" />
          </div>
          <div className="input-wrapper">
            <input type="text" size="35" name="business" placeholder="Business" />
          </div>
          <input type="submit" value="Submit" />
        </StyledForm>
        {formState === 'SENDING' && (
          <div className="sending">
            <p>Sending...</p>
          </div>
        )}
        {formState === 'SUCCESS' && (
          <div className="success">
            <p>Your message has been sent! We will be contacting you shortly.</p>
          </div>
        )}
        {formState === 'FAIL' && (
          <div className="fail">
            <p>Uh oh! Something went wrong. Please try again later, or give us a call at 920-983-9700.</p>
          </div>
        )} */}
        </FormGroup>
      )}
      {fields.smoke_image && (
        <StyledImg
          className="smoke"
          image={fields.smoke_image.childImageSharp.gatsbyImageData}
          alt={fields.smoke_alt}
        />
      )}

      <StyledDiv>
        <div className="row">
          <div className="address-block">
            <a.div className="panel" style={panelPos1}>
              <div className="content-wrap">
                <h2>De Pere</h2>
                <p>
                  2081 Profit Place
                  <br />
                  De Pere, WI 54115
                </p>
                <p>
                  <a href="https://goo.gl/maps/R2AQsZTrtYFcgqzy7">Directions</a>
                </p>
              </div>
            </a.div>
            <GatsbyImage image={fields.location_bg1.childImageSharp.gatsbyImageData} alt={fields.location_1_alt} />
          </div>
          <div className="addl-info">
            <h2>Phone</h2>
            <p>
              <a href="tel:9209839700">920.983.9700</a>
            </p>
            <h2>E-mail</h2>
            <p>
              <a href="mailto:info@goelement.com">info@goelement.com</a>
            </p>
          </div>
        </div>
      </StyledDiv>
    </>
  );
};

export default WebsiteRequest;
