import React from 'react';
import styled from 'styled-components';
import bgimg from '../img/WHO-background-with-hexes.jpg';
import Crew from '../components/Crew';
import { Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

const StyledDiv = styled.div`
  background-image: url(${bgimg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  display: flex;
  flex-direction: column;
  padding: 185px 0 0;
  @media (max-width: 1020px) {
    padding: 55px 0 0;
  }
  @media (max-width: 640px) {
    padding: 0;
  }
  .you-will-be-assimilated {
    text-align: center;
    margin: 100px 0 50px;
    @media (max-width: 1020px) {
      margin: 0;
    }
    p {
      font-family: 'Oswald';
      text-transform: uppercase;
      letter-spacing: 1.6px;
      margin: 0 0 52px;
      font-size: 4rem;
      line-height: 62px;
      color: white;
      font-weight: 400;
      transition: all 200ms linear;
      @media (max-width: 600px) {
        font-size: 3rem;
      }
    }
  }
`;

const StyledContainer = styled.div`
  max-width: 1070px;
  margin: 0 auto;
  @media (max-width: 1020px) {
    padding: 0;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8rem;
    padding: 0 30px 0 0;
    h2 {
      font-family: 'Oswald';
      font-size: 1.9rem;
      font-weight: 400;
      line-height: 1.6;
      margin: 0;
      margin-bottom: 1.125rem;
      letter-spacing: 1.4px;
      color: #009bce;
      transition: all 200ms linear;
      @media (max-width: 600px) {
        font-size: 1.4rem;
      }
    }
    p {
      font-family: 'Roboto';
      font-weight: 300;
      letter-spacing: 1.3px;
      font-size: 1.25rem;
      line-height: 1.55;
      transition: all 200ms linear;
      margin: 0 auto;
      opacity: 0.8;
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
    &:nth-child(2n) {
      flex-direction: row-reverse;
      padding: 0 0 0 30px;
    }
    @media (max-width: 1020px) {
      flex-wrap: wrap;
      padding: 0;
      &:nth-child(2n) {
        padding: 0;
      }
      .blurb {
        padding: 30px 30px 0;
      }
      .spacer {
        display: none;
      }
    }
  }
  .gatsby-image-wrapper {
    flex: 0 0 auto;
  }
  .spacer {
    display: block;
    width: 3rem;
    flex: 0 0 auto;
  }
`;

const WhoPage = ({ fields }) => {
  return (
    <>
      <StyledDiv>
        <StyledContainer className="wrapper">
          {fields.about_us.map((row, index) => (
            <div className="row" key={index}>
              <div
                style={{
                  flex: '1 0 auto',
                  width: '100%',
                  margin: '0 auto',
                  maxWidth: row.imgMaxWidth,
                }}
              >
                <GatsbyImage image={row.img.childImageSharp.gatsbyImageData} alt={row.alt} />
              </div>
              <span className="spacer" />
              <div className="blurb" dangerouslySetInnerHTML={{ __html: row.text }} />
            </div>
          ))}
        </StyledContainer>
        <div className="you-will-be-assimilated">
          <p>Join our Crew</p>
          <Link className="cta" to="/agency-careers/">
            Job Openings
          </Link>
        </div>
      </StyledDiv>
      <Crew />
    </>
  );
};

export default WhoPage;
