// /case-studies
import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledDiv = styled.div`
  margin: 100px auto;
  width: calc(100vw - 200px);
  @media (min-width: 1600px) {
    max-width: 1400px;
  }
  h2 {
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 4rem;
    line-height: 4rem;
    text-align: center;
    font-weight: 400;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    max-height: 1100px;
    @media (max-width: 1300px) {
      max-height: 750px;
    }
    @media (max-width: 1040px) {
      max-height: 1300px;
      .item {
        width: 50%;
        display: flex;
        flex-direction: column;
      }
    }
    @media (max-width: 850px) {
      max-height: 1000px;
    }
    @media (max-width: 760px) {
      max-height: none;
      .item {
        width: 100%;
      }
    }
  }
  &.featured {
    .wrapper {
      max-height: 1890px;
      @media (max-width: 1334px) {
        max-height: 1515px;
      }
      @media (max-width: 1120px) {
        max-height: 1230px;
      }
      @media (max-width: 1040px) {
        max-height: 2520px;
      }
      @media (max-width: 921px) {
        max-height: 2165px;
      }
      @media (max-width: 820px) {
        max-height: 1860px;
      }
      @media (max-width: 760px) {
        max-height: none;
      }
    }
  }
  @media (max-width: 1040px) {
    &.case-studies {
      .wrapper {
        .item {
          &:last-of-type {
            order: 3;
          }
          &:nth-of-type(3) {
            order: 5;
          }
        }
      }
    }
    &.featured {
      .wrapper {
        .item {
          &:nth-of-type(6) {
            order: 3;
          }
          &:nth-of-type(3) {
            order: 5;
          }
        }
      }
    }
  }
  .item {
    flex: 0 1 auto;
    max-width: 550px;
    width: 33.331%;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background-color: rgba(0, 0, 0, 0);
      transition: all 380ms ease-in-out;
      cursor: pointer;
    }
    &:hover::after {
      background-color: rgba(0, 0, 0, 0.55);
    }
    button.cta {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
    a.itemLink {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -11;
    }
    &:hover a.itemLink {
      z-index: 11;
    }
  }

  @media (max-width: 500px) {
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    .item {
      a.itemLink {
        z-index: 11;
      }
      &:after {
        background-color: rgba(0, 0, 0, 0.55);
      }
      &:hover,
      &:focus {
        a.itemLink {
          button {
            outline: 1px solid white;
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

const WhyPage = ({ fields }) => {
  return (
    <>
      <StyledDiv className="case-studies" id="case-studies">
        <h2>{fields.project_title}</h2>
        <div className="wrapper">
          {fields.projects.map((row) => (
            <div className="item" key={row.title + row.link}>
              <Link className="itemLink" to={row.link}>
                <button className="cta">{row.title}</button>
              </Link>
              <GatsbyImage image={row.thumb.childImageSharp.gatsbyImageData} alt={row.alt} />
            </div>
          ))}
        </div>
      </StyledDiv>

      <StyledDiv className="featured" id="featured-projects">
        <h2>{fields.featured_title}</h2>
        <div className="wrapper">
          {fields.featured.map((row) => (
            <div className="item" key={row.title + row.link}>
              <Link className="itemLink" to={row.link}>
                <button className="cta">{row.title}</button>
              </Link>
              <GatsbyImage image={row.thumb.childImageSharp.gatsbyImageData} alt={row.alt} />
            </div>
          ))}
        </div>
      </StyledDiv>
    </>
  );
};

export default WhyPage;
