import React, { useCallback, useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Carousel from '../components/Carousel';
import { GatsbyImage } from "gatsby-plugin-image";

import { useSpring, useSprings, animated as a, to } from 'react-spring';
import { interpolate as applyKeyframe } from '@popmotion/popcorn';
import { mapValues as _mapValues, map as _map, get as _get } from 'lodash';

const StyledImg = styled(GatsbyImage)`
  position: absolute !important;
  width: 40%;
  right: 0;
  z-index: 0;
`;

const StyledAside = styled.div`
  display: flex;
  position: relative;
  font-family: 'Oswald';
  font-weight: 400;
  letter-spacing: 1.4px;
  font-size: 1.9rem;
  width: 100%;
  overflow: hidden;
  padding-bottom: 140px;
  margin-top: 120px;
  h2,
  p {
    margin: 1rem 0px;
  }
  .first,
  .second {
    line-height: 1.4;
    font-weight: 400;
    will-change: transform;
  }
  .first {
    background: #fff;
    color: rgba(0, 0, 0, 0.75);
    h2 {
      color: #007bb6;
      font-size: inherit;
      font-weight: inherit;
    }
    width: calc(50% + 50px);
    & > div {
      margin-right: -100px;
    }
    @media (max-width: 1040px) {
      width: 50%;
      & > div {
        margin: 0 auto;
        padding: 0 30px;
      }
    }
  }
  .second {
    background: #007bb6;
    color: rgba(255, 255, 255, 0.91);
    h2 {
      color: black;
      font-size: inherit;
      font-weight: inherit;
    }
    width: calc(50% - 50px);
    & > div {
      margin-right: -45px;
    }
    @media (max-width: 1040px) {
      width: 50%;
      & > div {
        margin: 0 auto;
        padding: 0 30px;
      }
    }
  }
  & > div {
    height: 308px;
    background: #ddffff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  & > div > div {
    max-width: 440px;
    text-align: center;
  }
  & > div:nth-child(2) {
    margin-top: 140px;
  }
  @media (max-width: 900px) {
    font-size: 1.5rem;
  }
`;

const StyledBodyCopy = styled.div`
  height: 550px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: 'Oswald';
    font-size: 1.9rem;
    line-height: 1.6;
    margin: 0;
    margin-bottom: 1.125rem;
    font-weight: 400;
    text-align: center;
    color: #007bb6;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }
  font-family: 'Roboto';
  font-weight: 300;
  letter-spacing: 1.3px;
  font-size: 1.25rem;
  line-height: 1.9;
  transition: all 200ms linear;
  @media (max-width: 600px) {
    font-size: 1rem;
  }

  & > div {
    max-width: 920px;
    padding: 0 30px;
  }
`;

const StyledGetRealValues = styled.div`
  display: flex;
  min-height: 120vh;
  .gatsby-image-wrapper {
    flex: 1 1 50%;
    max-width: calc(50% + 60px);
    position: sticky !important;
    top: 50px;
    max-height: 100vh;
  }
  .list {
    flex: 1 1 50%;
    max-width: calc(50% - 100px);
    padding-top: 66vh;
  }
  .list > div {
    margin-bottom: 15rem;
    letter-spacing: 1.3px;
    font-size: 1.25rem;
    line-height: 1.9;
    color: #dadada;
    font-weight: 300;
    max-width: 400px;
    padding-right: 30px;
    transition: all 200ms linear;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
    h2 {
      font-family: 'Oswald';
      font-weight: 400;
      letter-spacing: 1.4px;
      font-size: 1.9rem;
      line-height: 1.6;
      margin-bottom: 1.125rem;
      color: #007bb6;
      transition: all 200ms linear;
      @media (max-width: 600px) {
        font-size: 1.4rem;
      }
    }
  }
  @media (max-width: 1060px) {
    .gatsby-image-wrapper,
    .list {
      max-width: 50%;
    }
  }
  @media (max-width: 800px) {
    .gatsby-image-wrapper {
      max-width: 40%;
    }
    .list {
      max-width: 60%;
    }
  }
  @media (max-width: 800px) {
    .gatsby-image-wrapper {
      max-width: 20%;
    }
    .list {
      max-width: 80%;
    }
  }
`;

const ScrollerDiv = styled.div`
  z-index: 33;
  /* background: rgba(255,255,255,0.4); */
  flex: 0 1 40px;
  height: 80vh;
  position: sticky;
  top: 80px;

  .bar {
    position: relative;
    height: 100%;
    width: 1px;
    background-color: #009bce;
  }

  .scroller {
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 1px;
    background: white;
  }

  .label {
    position: absolute;
    transform-origin: bottom left;
    transform: rotate(-90deg);
    bottom: 0;
    width: 200px;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 0.9rem;
    letter-spacing: 1.5px;
  }
`;

const Scroller = () => {
  const ref = useRef();

  const [{ st }, set] = useSpring(() => ({ st: 0, config: { mass: 1, tension: 265, friction: 25 } }));
  const interpPos = st.to((st) => `translateY(-${st}px)`);
  const displayText = st
    .to({ extrapolate: 'clamp', range: [0, 201, 409], output: [0, 1, 2] })
    .to((x) => ['Problem Solving', 'Partnership', 'Results'][Math.floor(x)]);
  // const onScroll = useCallback(yp => { }, [0])

  useEffect(() => {
    let yPos = 0;
    const startPos = ref.current.parentElement.offsetTop;
    const tick = () => {
      timerID = window.requestAnimationFrame(tick);
      if (yPos !== window.scrollY) {
        if (window.scrollY > startPos) set.start({ st: (window.scrollY - startPos) / 3.4 });
        else set.start({ st: 0 });
        yPos = window.scrollY;
      }
    };
    let timerID = window.requestAnimationFrame(tick);
    return function cleanup() {
      window.cancelAnimationFrame(timerID);
    };
  });
  return (
    <ScrollerDiv className="xyz" ref={ref}>
      <div className="bar">
        <a.div className="scroller" style={{ transform: interpPos }}>
          <a.div className="label">{displayText}</a.div>
        </a.div>
      </div>
    </ScrollerDiv>
  );
};

const interpolateKeyframe = (index, progress) => {
  let config = { keyframes: [], output: [] };
  let result = { x: 0, y: 0 };
  if (index === 0) {
    config = {
      keyframes: [0, 0.8, 1],
      output: [
        { x: -800, y: 0 },
        { x: -150, y: 10 },
        { x: 0, y: 140 },
      ],
    };
  } else if (index === 1) {
    config = {
      keyframes: [0, 0.8, 1],
      output: [
        { x: 800, y: 0 },
        { x: 150, y: 10 },
        { x: 0, y: 140 },
      ],
    };
  }

  let collection = config.output;
  if (collection.length) {
    result = _mapValues(collection[0], (value, key) => {
      let prop = _map(collection, (x) => _get(x, key));
      return applyKeyframe(config.keyframes, prop)(progress);
    });
  }

  return result;
};

const WhenPage = ({ fields }) => {
  const CarouselData = useStaticQuery(graphql`
    {
      allCarouselApproachHJson {
        edges {
          node {
            id
            img {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 572 )
              }
            }
            alt
            title
            url
          }
        }
      }
    }
  `);

  const onScroll = useCallback(
    (e) => {
      const startPos = 350;
      if (window.scrollY > startPos) {
        set.start((index) => {
          const progress = (window.scrollY - startPos) / 360;

          const obj = interpolateKeyframe(index, progress);
          return { progress: progress, x: obj.x, y: obj.y, config: { mass: 4, tension: 250, friction: 60 } };
        });
      }
    },
    [0]
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return function cleanup() {
      window.removeEventListener('scroll', onScroll);
    };
  });

  const [trail, set] = useSprings(2, (index) => {
    const obj = interpolateKeyframe(index, 0);
    return { progress: 0, x: obj.x, y: obj.y };
  });

  return (
    <>
      {fields.smoke_image && <StyledImg image={fields.smoke_image.childImageSharp.gatsbyImageData} alt={fields.smoke_alt} />}
      <StyledAside>
        {trail.map(({ x, y }, index) => (
          <a.div
            className={index === 0 ? 'first' : 'second'}
            key={index}
            style={{ transform: to([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }}
          >
            <div dangerouslySetInnerHTML={{ __html: index === 0 ? fields.aside.first : fields.aside.second }} />
          </a.div>
        ))}
      </StyledAside>

      <StyledBodyCopy>
        <div dangerouslySetInnerHTML={{ __html: fields.body_copy }} />
      </StyledBodyCopy>

      <StyledGetRealValues>
        <GatsbyImage className="test" image={fields.hex_art_image.childImageSharp.gatsbyImageData} alt={fields.hex_art_alt} />
        <Scroller />
        <div className="list">
          {fields.get_real_values.map((value, index) => {
            return <div key={index} dangerouslySetInnerHTML={{ __html: value }} />;
          })}
        </div>
      </StyledGetRealValues>

      <Carousel CarouselData={CarouselData} />
    </>
  );
};

export default WhenPage;
